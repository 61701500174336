
import { Options, Vue } from "vue-class-component";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import getFormattedDate from "@/app/infrastructures/misc/common-library/GetFormattedDate";
import downloadFile from "@/app/infrastructures/misc/common-library/DownloadFile";
import fileNameFromUrl from "@/app/infrastructures/misc/common-library/FileNameFromUrl";
import fileExtensionFromUrl from "@/app/infrastructures/misc/common-library/FileExtensionFromUrl";

@Options({
  components: {}
})
export default class ModalDetailTopupManual extends Vue {
  created() {
    this.getDetail();
  }

  getDetail() {
    BalanceController.getDetailTopupManual();
  }

  get isShowRequestTopupManualDetail() {
    return BalanceController.isShowRequestTopupManualDetail;
  }
  showModalRequestTopupManualDetail(val: boolean) {
    BalanceController.setShowRequestTopupManualDetail(val);
    BalanceController.setShowTopupTransactionHistory(!val);
  }
  get detailData() {
    return BalanceController.requestTopupManualDetail;
  }
  get isLoading() {
    return BalanceController.isLoadingDetail;
  }
  get isError() {
    return BalanceController.isErrorDetail;
  }
  get errorCause() {
    return BalanceController.errorCauseDetail;
  }
  formatPrice(val: number) {
    return formatPrice(val);
  }
  formatDate(date: any) {
    date = getFormattedDate(new Date(date))?.split(" ");
    date?.splice(3, 1);
    return date?.join(" ");
  }
  get fileName() {
    return `${fileNameFromUrl(
      this.detailData.tumFileOfProof
    )}${fileExtensionFromUrl(this.detailData.tumFileOfProof)}`;
  }
  get isFilePdf() {
    return fileExtensionFromUrl(this.detailData.tumFileOfProof)?.includes(
      "pdf"
    );
  }
  downloadFile(url: string) {
    downloadFile(url, fileNameFromUrl(url), fileExtensionFromUrl(url));
  }
}
